



























import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import * as Papaparse from 'papaparse'

@Component
export default class VueComponent extends Vue {
  show = false
  vinTextBox = ''
  vinFile: File | null = null

  saving = false

  @Watch('show')
  onShowChange() {
    this.vinFile = null
    this.vinTextBox = ''
    this.saving = false
  }

  @Emit('vin-change')
  notifyVinChange() {
    return true
  }

  async upload() {
    this.saving = true

    let vins: string[] = []
    if (this.vinFile) {
      const data = await new Promise((resolve) => {
        if (this.vinFile) {
          Papaparse.parse(this.vinFile, { complete: (results: { data: string[][] }) => { resolve(results.data) } })
        }
      }) as string[][]

      if (data.length === 1) {
        vins = data[0]
      } else if (data.length > 1) {
        vins = []
        data.forEach((r) => vins.push(r[0]))
      }
    } else {
      vins = Papaparse.parse(this.vinTextBox).data[0] as string[]
    }

    vins = vins.map((v) => v.trim()).filter((v, i, a) => a.indexOf(v) === i).filter(Boolean)

    await this.$http.post('/api/update-user-vins', { userId: 1, vins })

    this.notifyVinChange()
    this.saving = false
    this.show = false
  }
}
