












































import { Component, Vue } from 'vue-property-decorator'
import VinCardLoading from '@/app/Dashboard/VinCard/VinCardLoading.vue'
import { VinValue } from '../../../../functions/src/vin/vin.value'
import VinCard from '@/app/Dashboard/VinCard/VinCard.vue'
import VinCardNone from '@/app/Dashboard/VinCard/VinCardNone.vue'
import VinUploadModal from '@/app/Dashboard/VinUpload/VinUploadModal.vue'

@Component({
  components: { VinUploadModal, VinCardNone, VinCard, VinCardLoading }
})
export default class VueComponent extends Vue {
  loading = {
    status: true,
    default: 21
  }

  showAll = false
  remoteVins: VinValue[] = []

  showModal = false

  async mounted() {
    await this.loadUserVins()
  }

  async loadUserVins() {
    this.loading.status = true

    const params = { userId: 1 }
    const response = await this.$http.get<VinValue[]>('/api/lookup-user-vins', { params })
    this.remoteVins = response.data.sort((a, b) => a.vin.localeCompare(b.vin))

    this.loading.status = false
  }

  get vins() {
    if (this.loading.status) {
      return Array(this.loading.default).keys()
    }

    if (this.showAll) {
      return this.remoteVins
    } else {
      return this.remoteVins.filter((v) => v.portalId)
    }
  }
}
