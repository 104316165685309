










import { Component, Prop, Vue } from 'vue-property-decorator'
import VinCardLoading from '@/app/Dashboard/VinCard/VinCardLoading.vue'

@Component({
  components: { VinCardLoading }
})
export default class VueComponent extends Vue {
  @Prop({ type: Boolean, default: false })
  onlyImpounded!: boolean
}
