















import { Component, Prop, Vue } from 'vue-property-decorator'
import VinCardLoading from '@/app/Dashboard/VinCard/VinCardLoading.vue'
import { VinValue } from '../../../../../functions/src/vin/vin.value'

@Component({
  components: { VinCardLoading }
})
export default class VueComponent extends Vue {
  @Prop({ type: Object, required: true })
  vin!: VinValue

  get daysSinceReported() {
    return this.$moment().diff(this.$moment(this.vin.reportedDate), 'days')
  }

  get color() {
    if (this.daysSinceReported > 30) {
      return '#EE9090'
    } else if (this.daysSinceReported > 15) {
      return '#eecc90'
    } else if (this.daysSinceReported) {
      return '#90ee90'
    } else {
      return '#d9d9d9'
    }
  }

  openLink() {
    if (this.vin.portalLink) {
      window.open(this.vin.portalLink, '_blank')
    }
  }
}
